/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;
  line-height: $base-line-height * $base-font-size * 2.25;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  @include media-query($on-palm) {
    padding-right: 45px;
  }

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

.site-nav {
  position: absolute;
  top: 9px;
  right: $spacing-unit / 2;
  background-color: $background-color;
  border: 1px solid $grey-color-light;
  border-radius: 5px;
  text-align: right;

  .nav-trigger {
    display: none;
  }

  .menu-icon {
    float: right;
    width: 36px;
    height: 26px;
    line-height: 0;
    padding-top: 10px;
    text-align: center;

    > svg path {
      fill: $grey-color-dark;
    }
  }

  label[for="nav-trigger"] {
    display: block;
    float: right;
    width: 36px;
    height: 36px;
    z-index: 2;
    cursor: pointer;
  }

  input ~ .trigger {
    clear: both;
    display: none;
  }

  input:checked ~ .trigger {
    display: block;
    padding-bottom: 5px;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;
    display: block;
    padding: 5px 10px;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 0;
    }
    margin-left: 20px;
  }

  @media screen and (min-width: $on-medium) {
    position: static;
    float: right;
    border: none;
    background-color: inherit;

    label[for="nav-trigger"] {
      display: none;
    }

    .menu-icon {
      display: none;
    }

    input ~ .trigger {
      display: block;
    }

    .page-link {
      display: inline;
      padding: 0;

      &:not(:last-child) {
        margin-right: 20px;
      }
      margin-left: auto;
    }
  }
}

.end_of_post {
  font-style: italic;
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  width: -webkit-calc(100% - (#{$spacing-unit} / 2));
  width: calc(100% - (#{$spacing-unit} / 2));
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1,
.footer-col-2 {
  width: -webkit-calc(50% - (#{$spacing-unit} / 2));
  width: calc(50% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(100% - (#{$spacing-unit} / 2));
  width: calc(100% - (#{$spacing-unit} / 2));
}

@media screen and (min-width: $on-large) {
  .footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width: calc(35% - (#{$spacing-unit} / 2));
  }

  .footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width: calc(20% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width: calc(45% - (#{$spacing-unit} / 2));
  }
}

@media screen and (min-width: $on-medium) {
  .footer-col {
    float: left;
  }
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1 0 auto;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

figcaption {
  font-style: italic;
  text-align: center;
  margin-bottom: 2em;
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title,
.post-content h1 {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @media screen and (min-width: $on-large) {
    @include relative-font-size(2.625);
  }
}

.post-content {
  margin-bottom: $spacing-unit;
  text-align: justify;

  h2 {
    @include relative-font-size(1.75);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(2);
    }
  }

  h3 {
    @include relative-font-size(1.375);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(1.625);
    }
  }

  h4 {
    @include relative-font-size(1.125);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(1.25);
    }
  }
}

hr {
  margin-top: 2em;
  margin-bottom: 2em;
  border: none;
  height: 1px;
  background-color: $grey-color-light;
}

/**
 * post comment
 */
#comments {
  margin-top: 2em;
}

.no_comment {
  margin-top: 3em;
  font-style: italic;
}

.comment-content {
  p {
    margin-bottom: 0;
  }
}

.comment-box {
  margin-bottom: 20px;
}

.hide {
  display: none;
}

#post_comment,
#search_box {
  div {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  span.req {
    color: #f74545;
    margin-left: 3px;
  }

  label {
    display: block;
  }

  textarea {
    width: 100%;
  }

  input {
    min-width: 70%;
  }

  textarea, input {
    font-size: 100%;
    width: 100%;
    display: block;

    border: 1px solid rgba(0, 0, 0, 0.15);
    background: none;
    -webkit-appearance: none;
    padding: 0.5em;
    box-sizing: border-box;
    border-radius: 0;
    color: #444;
  }
  textarea.req, input.req {
    border: 1px solid #f74545;
  }

  button {
    margin-top: 1em;
    background-color: #009688; // deepki ready base color
    border: none;
    padding: 0.5em 1.35em;
    color: #fff;
    font-size: 1em !important;
    margin-bottom: 2em;
    cursor: pointer;
    transition: background-color 140ms ease;
    -webkit-appearance: none;
  }
  button:hover {
    background-color: #064a43 // deepki ready dark green
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.social_badges {
  display: block;
  text-align: left;
  font-size: 75%;
  padding-bottom: 1.5em;
}

img.social {
  max-width: 18px;
  max-height: 18px;
  margin-right: 3px;
  margin-left: 3px;
}

/**
 * 404
 */
.not_found {
  margin: 10px auto;
  max-width: 600px;
  text-align: center;
  h1 {
    margin: 30px 0;
    font-size: 4em;
    line-height: 1;
    letter-spacing: -1px;
  }
}

// boutons prev/next
ul.pager {
  text-align: center;
}

ul.pager li {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
